.discover-nav {
    width: 100%;
    background: $c-background;
    margin-bottom: 20px;
}

.discover-nav__list {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px 0 9px;

    .discover-nav--gallery & {

        @include breakpoint(phablet) {
            flex-direction: column;
        }
    }
}

.discover-nav__item {
    margin: 0 15px;
    border-bottom: 1px solid transparent;
    transition: border-bottom .3s ease;
    text-align: center;
    line-height: 16px;

    &:hover {
        border-bottom: 1px solid $c-dark-grey;
    }
}

.discover-nav__link,
.discover-nav__tag {
    @include apply-map($f-primary);
    @include rem(12px);

    letter-spacing: 2px;
    font-weight: 600;
    color: $c-dark-grey;
    text-transform: uppercase;
    text-decoration: none;
}

.discover-nav__tag {
    cursor: pointer;
}
