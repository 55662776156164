.categories {
    margin: 0;
    padding: 105px 0 185px;
    position: relative;
    
    &::after {
        content: '';
        position: absolute;
        top: 0; 
        bottom: 0;
        right: calc(((100vw - 100%) / 2) * -1); // pull element to edge of viewport. 
        left: 80px;
        background: $c-background;
        z-index: -1;

        @include breakpoint(tablet) {
            left: calc(((100vw - 100%) / 2) * -1);   
        }

        @include breakpoint(phablet) {
            left: -$site-gutter-mobile;
            right: -$site-gutter-mobile;
        }
    }

    @include breakpoint(tablet) {
        margin: 0 0 50px 0;
        padding: 45px 0 60px 0;
    }
}

    .categories__header {
        margin-bottom: 30px;
        position: relative;
    }

        .categories__title {
            @include apply-map($f-title);
            @include rem(44px);

            line-height: 53px;
            color: $c-dark-grey;

            @include breakpoint(phablet) {
                @include rem(32px);

                line-height: 38px;
                letter-spacing: -0.5;
            }
        }