.sample-popup {
    margin: 20px;
    padding: 40px 10px;
    border: 1px solid $c-filter-grey;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(phablet) {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        margin: 13px;
        padding: 0 15px;
        justify-content: center;
    }
}

    .sample-popup__paint,
    .sample-popup__fabric {
        display: flex;
        justify-content: center;

        @include breakpoint(phablet) {
            flex-direction: column;
        }
    }

    .sample-popup__actions {
        display: flex;
        flex-direction: column;
        align-items: center;

        &:not(:last-child) {
            margin-right: 50px;
        }

        @include breakpoint(phablet) {

            &:not(:last-child) {
                margin: 0 0 40px 0;
            }
        }
    }

    .sample-popup__title {
        @include apply-map($f-title);
        @include rem(32px);

        line-height: 38px;
        color: $c-dark-grey;
        margin-bottom: 20px;
        text-align: center
    }

    .sample-popup__subtitle {
        @include apply-map($f-primary);
        @include rem(18px);

        line-height: 26px;
        color: $c-dark-grey;
        font-weight: 600;
        margin-bottom: 10px;
        text-align: center;
    }

    .sample-popup__radios {
        display: flex;
        margin-bottom: 20px;

        @include breakpoint(phablet) {
            flex-direction: column
        }
    }

        .sample-popup__radio {
            @include rem(14px);

            display: flex;
            align-items: center;
            color: $c-dark-grey;
            line-height: 16px;
            margin-left: 15px;

            &:hover {
                cursor: pointer;
            }

            @include breakpoint(phablet) {

                &:first-of-type {
                    margin-bottom: 10px;
                }
            }
        }

            .sample-popup__input {
                display: flex;

                input {
                    opacity: 0;
                    width: 0;
                    height: 0;

                    &:checked + .sample-popup__control {
                        background: radial-gradient($c-dark-grey 50%, rgba(255, 0, 0, 0) 51%);
                    }
                }
            }

            .sample-popup__control {
                background: $c-white;
                display: block;
                width: 20px;
                height: 20px;
                border-radius: 50%;
                border: 1px solid $c-dark-grey
            }

            .sample-popup__label {
                line-height: 1;
                margin-left: 10px;
            }

        .sample-popup__text {
            @include rem(14px);

            color: $c-dark-grey;
            line-height: 16px;
        }

    .sample-popup__copy {
        @include apply-map($f-primary);
        @include rem(14px);

        line-height: 22px;
        text-align: center;
        color: $c-block-copy;
    }

    .sample-popup__form {
        margin-top: 20px;
    }

    .sample-popup__form--flex {
        display: flex;
        align-items: center;
    }

        .sample-popup__price {
            @include apply-map($f-primary);
            @include rem(28px);

            line-height: 33px;
            letter-spacing: 0.5px;
            color: $c-dark-grey;
            margin-right: 25px;
        }

        .sample-popup__button {

            span {
                @include apply-map($f-primary);
                @include rem(12px);

                line-height: 26px;
                font-weight: 600;
                letter-spacing: 2.5px;
                padding: 10px 20px;
            }
        }
