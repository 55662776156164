@use 'sass:math';

.product-listing {
    display: block;
    position: relative;
    width: 100%;
    opacity: 1;
    transition: opacity 1000ms;
    align-self: flex-start;
    grid-row: span 1;
    grid-column: span 1;
    text-decoration: none;

    &:hover {
        .product-listing__content__hover {
            transition: opacity .2s ease;
            opacity: 1;
        }
    }

    &.product-listing--large {
        &::after {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 200px;
            opacity: 0.4;
            background: linear-gradient(0deg, $c-black 0%, rgba(0,0,0,0) 100%);
            z-index: 1;
        }
    }
}

.product-listing--loading {
    opacity: 0.3;
    cursor: wait;
}

.product-listing--to-load {
    opacity: 0;

    .no-js & {
        opacity: 1;
    }
}

    .product-listing__link {
        display: block;
        text-decoration: none;
    }

        .product-listing__image {
            @include aspect(math.div(5, 6));

            position: relative;
            background-color: $c-background;

            &:has(img.rollover:hover) {
                img:not(.rollover) {
                    opacity: 0;
                    transition: opacity .3s ease;
                }
            }

            img {
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                object-fit: cover;

                &:not([src*="cylindo"]) {
                    mix-blend-mode: multiply;
                }

                &:not(.rollover) {
                    padding: 12px 10px;
                }

                .product-listing--large & {
                    padding: 0;
                }
            }

            img.product-listing__partner {
                position: absolute;
                top: 10px;
                right: 10px;
                left: auto;
                pointer-events: none;
                width: 36px;
                height: auto;
                padding: 0;
            }

            img.product-listing__partner--wide {
                height: 32px;
                width: auto;
                max-height: 10%;
                top: 7px;
            }

            svg.icon-sophie-paterson,
            svg.icon-aqua-clean {
                position: absolute;
                top: 10px;
                left: 10px;
                pointer-events: none;
            }

            div.product-listing__outlet {
                position: absolute;
                top: 20px;
                left: 20px;
                z-index: 1000;

                @include apply-map($f-primary);
                @include rem(12px);
                font-weight: bold;

                border: 1px solid $c-brand;
                border-radius: 15px;
                background-color: $c-white;
                color: $c-brand;
                padding: 3px 10px;
            }

            div.product-listing__message {
                @include font(12px, 26px);

                position: absolute;
                top: 10px;
                right: 10px;
                z-index: 1000;
                letter-spacing: 1.5px;
                font-weight: 600;
                background-color: $c-white;
                color: $c-brand;
                padding: 2px 15px;
                text-transform: uppercase;

                &.product-listing__message--top-left {
                    left: 10px;
                    right: auto;
                }

                &.product-listing__message--bottom-right {
                    left: auto;
                    top: auto;
                    right: 15px;
                    bottom: 10px;
                }

                &.product-listing__message--bottom-left {
                    top: auto;
                    bottom: 10px;
                    left: 10px;
                    right: auto;
                }

                &.product-listing__message--grey {
                    color: $c-white;
                    border-color: $c-mid-grey;
                    background-color: $c-mid-grey;
                    font-weight: normal;
                    letter-spacing: 1px;
                }

                &.product-listing__message--hide {
                    display: none;
                }
            }
        }

        .product-listing__image--full-bleed {
            img:not(.rollover) {
                padding: 0;
                mix-blend-mode: unset;
            }
        }

        .product-listing__image--clear {
            background: none;
        }

        .product-listing--callout {
            grid-column: span 2;

            &.product-listing--2x1 {
                grid-column: span 2;
                grid-row: span 1;
            }

            &.product-listing--2x2 {
                grid-column: span 2;
                grid-row: span 2;
            }

            &.product-listing--1x1 {
                grid-column: span 1;
                grid-row: span 1;
            }
        }

        .product-listing--callout .product-listing__image {
            &:before {
                width: 100%;
                background: linear-gradient(180deg, rgba($c-black, 0) 0%, rgba($c-black, 0) 60%, $c-black 100%);
                opacity: .5;
                position: relative;
                z-index: 1;
            }
            img {
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .product-listing__badge {
            position: absolute;
            top: 10px;
            left: 10px;
            height: 82px;
            width: 82px;
            z-index: 1;
            margin: 5px;

            svg {
                height: 100%;
                width: 100%;
                stroke: $c-black;
            }

            @include breakpoint(tablet) {
                height: 60px;
                width: 60px;
            }
        }

            .product-listing__content__bottom {
                padding-top: 8px;

                .product-listing--large & {
                    background-color: transparent;
                    padding: 0 30px;
                    bottom: 20px;
                    z-index: 10;
                    height: auto;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    text-align: center;

                    @include breakpoint(tablet) {
                        bottom: 10px;
                        padding: 0 15px;
                    }

                    & > *  {
                        flex: 1 1 0px;
                    }
                }
            }


            .product-listing__content__bottom--middle {
                top: 50%;
                transform: translateY(-50%);
                padding: 20px;
                bottom: auto;

                .small-title {
                    margin-bottom: 15px;
                }

                .button--greyscale {
                    background-color: transparent;
                }
            }

            .product-listing__content__bottom--trending {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                top: 50%;
                transform: translateY(-50%);

                .small-title {
                    margin-bottom: 15px;
                }

                .button--greyscale {
                    background-color: transparent;
                }
            }

            .product-listing--callout .product-listing__content__bottom {
                @include rem(32px);
                line-height: calc(38 / 32);

                padding: 50px 29px 29px;
                background-color: transparent;
                color: $c-white;
                @include lightondark;
            }

                .product-listing__button {
                    margin-top: 8px;
                }

                .product-listing__outline {
                    display: flex;

                    img {
                        width: 60px;
                        position: relative;

                        @include breakpoint(tablet) {
                            width: 45px;
                        }
                    }

                    .product-listing--large & {
                        img {
                            padding: 0;
                        }
                    }
                }

                .product-listing__title {
                    @include font(16px, 26px);

                    text-align: left;
                    letter-spacing: 0.25px;
                    transition: color .3s ease;
                    font-weight: 600;
                    margin-bottom: -2px;

                    @include breakpoint(tablet) {
                        @include font(14px, 18px);
                    }

                    .product-listing:not(.product-listing--large):hover & {
                        color: $c-block-copy;
                    }

                    .product-listing--large & {
                        @include apply-map($f-title);
                        @include font(32px, 38px);

                        color: $c-white;
                        font-style: italic;
                        text-align: center;
                        font-weight: 700;
                        display: inline-block;

                        @include breakpoint(tablet) {
                            @include font(22px, 28px);
                        }
                    }
                }

                    .product-listing__price {
                        @include font(14px, 26px);

                        color: $c-dark;
                        display: flex;
                        align-items: baseline;
                        gap: 2px;
                        line-height: 27px;

                        @include breakpoint(tablet) {
                            @include font(12px, 26px);
                        }

                        .product-listing--large & {
                            @include font(18px, 26px);

                            color: $c-white;
                            text-align: right;
                            display: inline-block;
                        }
                    }

                    .product-listing__from {
                        @include font(12px, 26px);

                        letter-spacing: 0.5;
                        margin-right: 3px;
                        color: $c-block-copy;

                        @include breakpoint(tablet) {
                            letter-spacing: 0.25px;
                        }

                        .product-listing--large & {
                            @include font(11px, 16px);

                            color: $c-white;
                        }

                        &.product-listing__from--empty {
                            display: none;
                        }
                    }

                    .product-listing__price--wrapper {
                        display: flex;
                        align-items: baseline;
                    }

                    .product-listing__price--old {
                        @include font(12px, 26px);

                        letter-spacing: 0.25px;
                        display: inline-block;
                        text-decoration: line-through;
                        margin-right: 3px;
                    }

                    .product-listing__price--new {
                        display: inline-block;
                        color: $c-brand;
                        letter-spacing: 0.75;
                    }

                .product-listing__vat {
                    text-transform: uppercase;
                    @include rem(10px);
                    color: $c-label;
                }

                .product-listing__colourways__list span.product-listing__meta {
                    @include font(12px, 24px);

                    color: $c-block-copy;
                    margin-top: -4px;

                    @include breakpoint(tablet) {
                        @include font(12px, 16px);

                        margin-top: 0;
                    }

                    span {
                        color: $c-dark;
                    }
                }

            .product-listing__sale {
                position: absolute;
                top: 0;
                right: 0;
                text-transform: uppercase;
                text-align: center;
            }

                .product-listing__sale__title,
                .product-listing__sale__value,
                .product-listing__sale__text {
                    display: block;
                    line-height: calc(14 / 16);

                    @include rem(16px);

                    @include breakpoint(tablet) {
                        line-height: calc(8 / 12);

                        @include rem(12px);
                    }
                }

            .product-listing__sale--round {
                top: 6px;
                right: 9px;

                @include breakpoint(tablet) {
                    top: 5px;
                    right: 5px;
                }
            }

                .product-listing__sale--round .product-listing__sale__inner {
                    display: table-cell;
                    height: 86px;
                    width: 86px;
                    vertical-align: middle;
                    color: $c-brand;
                    background: rgba($c-white, 0.75);
                    border: 2px solid $c-brand;
                    border-radius: 100%;

                    @include breakpoint(mobile) {
                        height: 60px;
                        width: 60px;
                    }
                }

                    .product-listing__sale--round .product-listing__sale__title {
                        display: inline-block;
                        padding: 0 0 5px 0;
                        margin: 0 0 5px 0;
                        border-bottom: 2px solid $c-brand;
                    }

                    .product-listing__sale--round .product-listing__sale__text {
                        line-height: calc(14 / 12);

                        @include rem(12px);

                        @include breakpoint(tablet) {
                            line-height: calc(14 / 10);

                            @include rem(10px);
                        }
                    }

            .product-listing__sale--corner {
                width: 100%;
                padding: 20px 0 10px;
                color: $c-white;
                background: $c-brand;
                transform-origin: 50% 0;
                transform: translateX(50%) rotate(45deg);
                font-size: 0;

                @include breakpoint(tablet) {
                    padding: 15px 0 10px;
                }
            }

                .product-listing__sale--corner .product-listing__sale__title {

                    span {
                        display: inline-block;
                        padding: 5px 13px 3px;
                        border-bottom: 1px solid $c-white;

                        @include breakpoint(tablet) {
                            padding: 5px 10px 3px;
                        }
                    }
                }

                .product-listing__sale--corner .product-listing__sale__value,
                .product-listing__sale--corner .product-listing__sale__text {
                    display: inline-block;
                    width: 50%;
                    padding: 3px 3px 0;
                }

                .product-listing__sale--corner .product-listing__sale__value {
                    text-align: right;
                }

                .product-listing__sale--corner .product-listing__sale__text {
                    text-align: left;
                }

    .product-listing__options {
        display: none;

        .product-listing:hover & {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: rgba($c-black, 0.3);
            cursor: pointer;

            @include breakpoint(tablet) {
                display: none;
            }
        }

        .button {
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0;
            }

            span {
                pointer-events: none;
            }
        }
    }

    .product-listing__content__hover {
        .product-listing--large & {
            position: absolute;
            bottom: 50px;
            right: 0;
            max-width: 200px;
            padding-right: 30px;
            z-index: 3;

            @include breakpoint(tablet) {
                bottom: 40px;
                padding-right: 15px;
            }

            p {
                @include font(11px, 16px);

                color: $c-white;
                width: 100%;
                text-align: right;
                letter-spacing: 1px;
            }
        }
    }


    .product-listing__colourways__list {
        position: relative;
        z-index: 2;
        display: flex;
        flex-wrap: wrap;

        span {
            @include rem(12px);
            color: $c-body;
        }
    }

        .product-listing__colourways__item {
            width: 20px;
            height: 20px;
            position: relative;
            display: flex;
            border: 1px solid $c-filter-grey;
            overflow: hidden;
            margin: 0 5px 5px 0;
            transition: border-color 150ms ease-in;

            &:hover {
                border-color: $c-dark-grey;
            }
        }

        .product-listing__colourways__item--active {
            border-color: $c-dark-grey;
        }
