.outlet-contact {
    margin-bottom: 20px;
}

.outlet__wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px;
    margin-bottom: 20px;
    background-color: $c-grey;
}

.outlet__description {
    padding: 10px 20px;
    flex: 2;

    p {
        @include apply-map($f-primary);
        @include rem(14px);

        line-height: 22px;
        color: $c-block-copy;
        font-weight: 400;
    }

    a {
        color: $c-dark-grey;
        font-weight: 600;
    }
}

.outlet__contact-description {
    margin-bottom: 10px;

    p {
        @include apply-map($f-primary);
        @include rem(14px);

        line-height: 22px;
        color: $c-block-copy;
        font-weight: 400;
    }

    a {
        color: $c-dark-grey;
        font-weight: 600;
    }
}

.outlet__images {
    padding: 5px;
    flex: 1;
    text-align: center;

    a {
        color: $c-dark-grey;
        font-weight: 600;
        @include rem(14px);
    }
}

.outlet__carousel {}

    .outlet__carousel__image {
        @include aspect(1);

        img {
            width: 100%;
            height: auto;
        }
    }

.outlet__title {
    @include apply-map($f-title);
    @include rem(32px);

    line-height: 38px;
    color: $c-dark-grey;
    margin-bottom: 15px;
}

.outlet__meta {
    margin-bottom: 10px;
    @include apply-map($f-primary);
    @include rem(16px);

    .outlet__product-title {
        font-weight: bold;
    }
}

.outlet__reference {
    margin-bottom: 20px;
}

.outlet__options {
    display: flex;
    flex-direction: column;
    padding: 5px 10px;
    margin-bottom: 20px;
    background-color: $c-grey;
}

.outlet__option {
    display: block;
    margin: 2px 0;
    position: relative;

    a {
        color: $c-dark-grey;
        font-weight: 600;
        @include rem(14px);
    }

    span {
        display: inline-block;
        position: absolute;
        right: 0;
        @include rem(14px);
    }
}
