.am-delivery {
    width: 24px;
    height: 14px;
}

.am-logo {
    width: 284px;
    height: 25px;
}

.am-pin {
    width: 16px;
    height: 21px;
}

.arrow-large-down {
    width: 14px;
    height: 8px;
}

.arrow-large-new {
    width: 8px;
    height: 14px;
}

.arrow-large-white {
    width: 8px;
    height: 14px;
}

.arrow-large {
    width: 8px;
    height: 14px;
}

.arrow-small-down {
    width: 9px;
    height: 6px;
}

.arrow-small-left {
    width: 6px;
    height: 9px;
}

.arrow-small-right {
    width: 6px;
    height: 9px;
}

.arrow-small-up {
    width: 9px;
    height: 6px;
}

.call {
    width: 21px;
    height: 21px;
}

.discover-am {
    width: 28px;
    height: 28px;
}

.dot-active {
    width: 30px;
    height: 30px;
}

.dot-inactive {
    width: 24px;
    height: 24px;
}

.download-cad {
    width: 18px;
    height: 21px;
}

.download-sketchup {
    width: 16px;
    height: 21px;
}

.icon-360 {
    width: 57px;
    height: 29px;
}

.icon-account {
    width: 21px;
    height: 20px;
}

.icon-alert {
    width: 26px;
    height: 26px;
}

.icon-am-logo {
    width: 284px;
    height: 25px;
}

.icon-american-express {
    width: 200px;
    height: 125px;
}

.icon-andrew-martin-full {
    width: 337px;
    height: 31px;
}

.icon-andrew-martin-short {
    width: 64px;
    height: 23px;
}

.icon-aqua-clean {
    width: 49px;
    height: 49px;
}

.icon-arrow-down {
    width: 10px;
    height: 7px;
}

.icon-arrow-large-left {
    width: 8px;
    height: 14px;
}

.icon-arrow-large-right {
    width: 8px;
    height: 14px;
}

.icon-arrow-large {
    width: 14px;
    height: 8px;
}

.icon-arrow-up {
    width: 10px;
    height: 7px;
}

.icon-back {
    width: 10px;
    height: 15px;
}

.icon-basket {
    width: 18px;
    height: 20px;
}

.icon-burger {
    width: 24px;
    height: 17px;
}

.icon-calculator-new {
    width: 16px;
    height: 21px;
}

.icon-calculator {
    width: 20px;
    height: 20px;
}

.icon-calendar {
    width: 40px;
    height: 40px;
}

.icon-camera {
    width: 21px;
    height: 18px;
}

.icon-close-thin-fill {
    width: 45px;
    height: 45px;
}

.icon-close-thin {
    width: 40px;
    height: 40px;
}

.icon-close {
    width: 14px;
    height: 14px;
}

.icon-collapse {
    width: 9px;
    height: 15px;
}

.icon-contact {
    width: 18px;
    height: 22px;
}

.icon-cylindo-ar {
    width: 18px;
    height: 21px;
}

.icon-delivery-box {
    width: 28px;
    height: 21px;
}

.icon-delivery-men {
    width: 55px;
    height: 42px;
}

.icon-diamond-outline {
    width: 92px;
    height: 92px;
}

.icon-diamond-sale {
    width: 92px;
    height: 92px;
}

.icon-diamond-steps {
    width: 70px;
    height: 70px;
}

.icon-diamond {
    width: 12px;
    height: 12px;
}

.icon-download-solid {
    width: 17px;
    height: 18px;
}

.icon-download {
    width: 17px;
    height: 18px;
}

.icon-durability {
    width: 87px;
    height: 87px;
}

.icon-email {
    width: 25px;
    height: 20px;
}

.icon-error {
    width: 24px;
    height: 24px;
}

.icon-exclamation {
    width: 5px;
    height: 19px;
}

.icon-expand {
    width: 9px;
    height: 15px;
}

.icon-external {
    width: 11px;
    height: 11px;
}

.icon-facebook {
    width: 9px;
    height: 16px;
}

.icon-filter-toggle {
    width: 24px;
    height: 14px;
}

.icon-filters {
    width: 15px;
    height: 13px;
}

.icon-gear {
    width: 24px;
    height: 24px;
}

.icon-google {
    width: 24px;
    height: 16px;
}

.icon-hamburger {
    width: 20px;
    height: 13px;
}

.icon-heart {
    width: 24px;
    height: 22px;
}

.icon-houzz-new {
    width: 30px;
    height: 22px;
}

.icon-houzz {
    width: 14px;
    height: 24px;
}

.icon-hq {
    width: 80px;
    height: 80px;
}

.icon-info {
    width: 15px;
    height: 15px;
}

.icon-instagram {
    width: 17px;
    height: 16px;
}

.icon-less {
    width: 12px;
    height: 8px;
}

.icon-logo-shape {
    width: 110px;
    height: 110px;
}

.icon-maestro-alt {
    width: 200px;
    height: 125px;
}

.icon-maestro {
    width: 200px;
    height: 125px;
}

.icon-mastercard-alt {
    width: 200px;
    height: 125px;
}

.icon-mastercard {
    width: 200px;
    height: 125px;
}

.icon-minus {
    width: 10px;
    height: 10px;
}

.icon-more {
    width: 12px;
    height: 8px;
}

.icon-odour {
    width: 87px;
    height: 87px;
}

.icon-opacity {
    width: 87px;
    height: 87px;
}

.icon-order-card {
    width: 25px;
    height: 25px;
}

.icon-padlock {
    width: 15px;
    height: 24px;
}

.icon-paint-brush {
    width: 26px;
    height: 25px;
}

.icon-pause-video {
    width: 28px;
    height: 39px;
}

.icon-paypal-alt {
    width: 285px;
    height: 178px;
}

.icon-paypal {
    width: 285px;
    height: 75px;
}

.icon-pdf {
    width: 18px;
    height: 21px;
}

.icon-pencil {
    width: 16px;
    height: 16px;
}

.icon-pinterest {
    width: 13px;
    height: 16px;
}

.icon-play-video {
    width: 28px;
    height: 39px;
}

.icon-play {
    width: 12px;
    height: 14px;
}

.icon-plus-retired {
    width: 10px;
    height: 10px;
}

.icon-plus-zoom {
    width: 18px;
    height: 20px;
}

.icon-plus {
    width: 9px;
    height: 9px;
}

.icon-quote {
    width: 231px;
    height: 196px;
}

.icon-sagepay-with-text {
    width: 132px;
    height: 46px;
}

.icon-sagepay {
    width: 135px;
    height: 36px;
}

.icon-scissors-new {
    width: 22px;
    height: 17px;
}

.icon-scissors {
    width: 24px;
    height: 24px;
}

.icon-search {
    width: 18px;
    height: 20px;
}

.icon-select {
    width: 9px;
    height: 6px;
}

.icon-share-facebook {
    width: 9px;
    height: 19px;
}

.icon-share-houzz {
    width: 80px;
    height: 26px;
}

.icon-share-pinterest {
    width: 21px;
    height: 22px;
}

.icon-share-twitter {
    width: 20px;
    height: 17px;
}

.icon-share {
    width: 16px;
    height: 18px;
}

.icon-small-arrow {
    width: 16px;
    height: 10px;
}

.icon-sofa {
    width: 50px;
    height: 35px;
}

.icon-sophie-paterson {
    width: 36px;
    height: 37px;
}

.icon-swatch {
    width: 15px;
    height: 15px;
}

.icon-tick-retired {
    width: 20px;
    height: 17px;
}

.icon-tick {
    width: 10px;
    height: 8px;
}

.icon-time {
    width: 87px;
    height: 87px;
}

.icon-tools {
    width: 40px;
    height: 40px;
}

.icon-twitter {
    width: 17px;
    height: 14px;
}

.icon-v12-card {
    width: 150px;
    height: 127px;
}

.icon-v12 {
    width: 300px;
    height: 127px;
}

.icon-visa-debit {
    width: 200px;
    height: 125px;
}

.icon-visa-electron {
    width: 220px;
    height: 145px;
}

.icon-visa {
    width: 200px;
    height: 125px;
}

.icon-waterbased {
    width: 87px;
    height: 87px;
}

.icon-will-it-fit {
    width: 20px;
    height: 20px;
}

.icon-wishlist {
    width: 20px;
    height: 18px;
}

.icon-youtube {
    width: 24px;
    height: 18px;
}

.icon-zoom {
    width: 23px;
    height: 23px;
}

.menu {
    width: 20px;
    height: 13px;
}

.message {
    width: 25px;
    height: 26px;
}

.quote {
    width: 231px;
    height: 196px;
}

.roomvo {
    width: 40px;
    height: 40px;
}

.tp-star-full {
    width: 31px;
    height: 29px;
}

.trustpilot {
    width: 216px;
    height: 22px;
}

