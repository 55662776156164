//// RESETS
// This file applies generic resets to the Onetrust cookie suite:
// The cookie banner, the cookie preference centre, and the cookie policy page
// All setting overrides listed below.
// If no overrides listed, settings default to variables in 'cookie-settings.scss'

// Reset typography on the cookie policy page

.cookie-audit {

}

    .ot-sdk-cookie-policy-title {
        font-weight: $cookie-fontweight !important;
        font-size: $cookie-h3-fontsize !important;
    }

    .ot-sdk-cookie-policy-group {
        font-weight: $cookie-fontweight !important;
        font-size: $cookie-h4-fontsize !important;
    }

    #cookie-policy-description,
    .ot-sdk-cookie-policy-group-desc {
        color: $cookie-fontcolor !important;
        font-weight: $cookie-fontweight !important;
        font-size: $cookie-copy-fontsize !important;
        line-height: $cookie-lineheight !important;
    }

// Reset cookie type table

#ot-sdk-cookie-policy-v2 {
    table {
        border-radius: 0 !important;

        th,
        td {
            padding: 10px;
        }
    }
}

//// Cookie Banner Styling ////
//
/// Reset cookie banner containers



// Banner button reset
#onetrust-accept-btn-handler,
#onetrust-reject-all-handler,
#accept-recommended-btn-handler,
.save-preference-btn-handler,
.ot-pc-refuse-all-handler,
#ot-sdk-btn {
    line-height: 1 !important;
    float: none !important;
    font-weight: $cookie-btn-fontweight !important;
    border-radius: $cookie-radius !important;
    font-size: $cookie-btn-fontsize !important;
    border: none !important;
    letter-spacing: $cookie-btn-letterspacing !important;
    white-space: nowrap !important;
    transition: background .3s ease, color .3s ease, border-color .3s ease !important;
    min-width: 0 !important;
    text-transform: $cookie-btn-texttransform !important;
    padding: 13px 24px !important;
    width: auto !important;

    &.ot-sdk-show-settings {
        background: $cookie-btn-bg !important;
        color: $cookie-btn !important;
    }

    &:hover,
    &:focus,
    &.ot-sdk-show-settings:hover,
    &.ot-sdk-show-settings:focus {
        background: $cookie-btn-onhover-bg !important;
        color: $cookie-btn-onhover !important;
        opacity: 1 !important;
    }
}

#onetrust-pc-btn-handler {
    font-size: $cookie-btn-fontsize !important;
    text-decoration: none !important;
    float: none !important;
    font-weight: $cookie-btn-fontweight !important;
    border-radius: $cookie-radius !important;
    border: none !important;
    letter-spacing: $cookie-btn-letterspacing !important;
    white-space: nowrap !important;
    min-width: 0 !important;
    text-transform: $cookie-btn-texttransform !important;
    padding: 0 !important;
    margin: 0 !important;
}

#onetrust-pc-sdk .ot-tgl input:checked+.ot-switch .ot-switch-nob {
    border: 1px solid $cookie-switch-fg !important;
    background-color: $cookie-switch-bg !important;

    &:before {
        border-color: $cookie-switch-fg !important;
        background-color: $cookie-switch-fg !important;
    }
}
#onetrust-pc-sdk .ot-cat-grp .ot-always-active {
    color: $cookie-titlecolor !important;
}
#ot-sdk-cookie-policy-v2.ot-sdk-cookie-policy a {
    background: none !important;
}


#onetrust-banner-sdk {
    padding: $cookie-wrappadding !important;
    overflow: hidden !important;
    @include breakpoint(tablet) {
        padding: $cookie-wrappadding-mobile !important;
    }
}

//// General styling
#onetrust-banner-sdk {

    #onetrust-policy-title {
        font-size: $cookie-title-fontsize !important;
        font-weight: $cookie-title-fontweight !important;
    }
}

//// Bar popup
.otFlat {

    #onetrust-banner-sdk,
    .ot-sdk-container,
    .ot-sdk-row,
    #onetrust-group-container,
    #onetrust-policy,
    #onetrust-policy-text,
    #onetrust-policy-title,
    #onetrust-button-group-parent,
    #onetrust-button-group,
    #onetrust-close-btn-handler,
    .onetrust-close-btn-handler {
        padding: 0 !important;
        margin: 0 !important;
        border: 0 !important;
        font-size: 100% !important;
        font: inherit !important;
        font-weight: $cookie-fontweight !important;
        float: none !important;
        line-height: 1 !important;
        vertical-align: baseline !important;
        width: auto !important;
        height: auto !important;
        box-sizing: border-box !important;
        opacity: 1 !important;
        transform: translate(0, 0) !important;

        &:not(#onetrust-banner-sdk) {
            position: static !important;
        }

        &::after,
        &::before {
            position: static !important;
            margin: 0 !important;
            padding: 0 !important;
            border: 0 !important;
        }
    }

    .ot-sdk-row::after {
        display: none !important;
    }

    .ot-sdk-row { /// Main flex container
        display: flex !important;
        max-width: $cookie-banner-maxwidth !important;
        flex-wrap: nowrap;
        justify-content: space-between;
        align-items: center;

        @include breakpoint(phablet) {
            flex-wrap: wrap;
        }

        #ot-pc-content & {
            display: block !important;
            margin-bottom: 5px !important;
        }
    }

    #onetrust-group-container { /// Flex child
        flex: 0 10 auto;

        @include breakpoint(phablet) {
            flex-basis: 100%;
        }
    }

    #onetrust-button-group-parent { /// Flex child
        flex: 0 0 auto;
        padding-right: 30px !important;
        flex-flow: row nowrap;
        justify-content: center;

        @include breakpoint(phablet) {
            flex-basis: 100%;
            margin-top: 10px !important;
            padding-right: 0px !important;
        }

        > * {
            flex: 1 0 auto;
        }
    }

    #onetrust-button-group { /// Button flex container
        display: flex !important;
        flex: 0 0 auto;
        flex-flow: row nowrap;
        justify-content: flex-end;
        align-items: center;

        #onetrust-pc-btn-handler {
            margin: 10px !important;
            flex: 0 0 auto;
            width: auto !important;
        }
        #onetrust-accept-btn-handler,
        #onetrust-reject-all-handler {
            flex: 0 0 auto;
            margin: 0 0 0 5px !important;
        }
        @include breakpoint(phablet) {
            justify-content: center;
            flex-wrap: wrap-reverse;
        }
    }

    #onetrust-close-btn-container {
        position: absolute !important;
        top: 50% !important;
        transform: translate(-50%, -50%) !important;
        right: 5px !important;

        @include breakpoint(phablet) {
            top: 5px !important;
            transform: translate(0, 0) !important;
        }
    }
}

//// Floating popup
.otFloatingRoundedCorner {
    max-width: 375px !important;

    #onetrust-policy {
        margin: 0 !important;

        > * {
            float: none !important;
        }

        .banner-header {
            margin: 0 0 1em !important;

            &:empty {
                display: none;
            }
        }
    }
        #onetrust-policy-text {
            padding: 0 !important;
            margin: 0 0 10px !important;
        }
    .ot-sdk-container {
       position: static !important;
    }
    #onetrust-group-container,
    #onetrust-button-group-parent {
        padding: 0 !important;
    }

    #onetrust-accept-btn-handler {
        margin: 10px 0 0 !important;
        width: 100% !important;
    }
    #onetrust-pc-btn-handler {
        margin: 10px 0 0 !important;
        width: auto !important;
    }

    #onetrust-banner-sdk button {
        margin: 0 !important;
    }

    #onetrust-close-btn-container {
        top: 10px !important;
        right: 10px !important;
    }

    #onetrust-button-group { /// Button flex container
        display: flex !important;
        flex-flow: row-reverse wrap;
        justify-content: center;
        align-items: center;

        > * {
            float: none !important;
            flex: 1 0 auto;
            width: auto !important;
            white-space: nowrap;
        }
    }
}

//// Floating banner
#onetrust-banner-sdk.otFloatingFlat {
    padding: 0;
    // max-width: 375px !important;

    #onetrust-policy {
        margin: 0 !important;
        padding: 0 !important;

        .banner-header {
            margin: 0 0 1em !important;

            &:empty {
                display: none;
            }
        }
        #onetrust-policy-text {
            padding: 0 !important;
            margin: 0 !important;
        }
    }

    .ot-sdk-container {
       padding: $cookie-wrappadding !important;

       @include breakpoint(tablet) {
           padding: $cookie-wrappadding-mobile !important;
       }
    }

    .banner-close-btn-container {
        position: absolute;
        top: 10px !important;
        right: 10px !important;
    }
        .onetrust-close-btn-handler {
            height: 12px !important;
            width: 12px !important;
            margin: 0 !important;
        }

    .ot-sdk-row {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .ot-sdk-eight {
            width: auto !important;
            flex: 2 2 300px;
            margin: 0 5px 0 0 !important;
        }
        .ot-sdk-four {
            width: auto !important;
            flex: 1 0 35%;
            margin: 0 !important;
        }
    }

    #onetrust-button-group { /// Button flex container
        display: flex !important;
        position: static !important;
        flex-direction: row-reverse;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin: 5px 0 !important;

        button {
            margin: 10px 0 !important;
        }
        #onetrust-pc-btn-handler {
            width: auto !important;
            margin-left: 10px !important;
            margin-right: 10px !important;
        }
    }
}

/// Modal-style Popup

#onetrust-banner-sdk.otCenterRounded {
    top: 50% !important;
    transform: translateY(-50%) !important;
    padding-left: 10px !important;
    padding-right: 10px !important;

    #onetrust-policy-title {
        font-size: $cookie-title-fontsize !important;
        font-weight: $cookie-title-fontweight !important;
    }

    #onetrust-policy-text {
        font-size: $cookie-fontsize !important;
        line-height: $cookie-lineheight !important;
        padding-right: 10px !important;

        @include breakpoint(phablet) {
            padding-right: 0px !important;
        }

        a {
            transition: color, .3s ease;

            &:hover {
                color: $cookie-fontcolor !important;
            }
        }
    }

    #onetrust-button-group {
        display: flex !important;
        flex-direction: row-reverse;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
    }

    button {
        margin: 5px !important;
    }

    .banner-actions-container {
        width: auto !important;
        margin: 5px !important;
    }
}


#onetrust-banner-sdk {

    #onetrust-policy-text {
        font-size: $cookie-fontsize !important;
        line-height: $cookie-lineheight !important;
        padding-right: 10px !important;

        @include breakpoint(phablet) {
            padding-right: 0px !important;
        }
    }

    .onetrust-close-btn-handler {
        height: 20px !important;
        width: 20px !important;
    }

    //// Privacy Center Styling

    .save-preference-btn-handler,
    .ot-pc-refuse-all-handler {
        height: auto !important;
        width: auto !important;
        margin: 20px 33px 20px 22px !important;
    }
    &:focus {
        outline: none !important;
    }
}
#onetrust-banner-sdk #onetrust-policy-text a,
#onetrust-consent-sdk #onetrust-pc-sdk .privacy-notice-link {
    display: inline !important;
    margin: 0 !important;
    font-weight: $cookie-fontweight !important;
}



#ot-pc-content.ot-pc-scrollbar {
    padding-bottom: 40px !important;
}

    #ot-pc-title,
    #ot-category-title,
    #ot-pvcy-hdr,
    .ot-cat-header,
    #ot-pvcy-txt,
    [id^="ot-header-id"] {
        text-transform: $cookie-texttransform !important;
        font-weight: $cookie-fontweight !important;
    }

    #ot-pc-title,
    #ot-category-title,
    #ot-pvcy-hdr,
    .ot-cat-header {
        font-size: $cookie-h4-fontsize !important;
    }

    #ot-pc-desc,
    .ot-grp-desc,
    #ot-pc-desc,
    #ot-pvcy-txt,
    [id^="ot-header-id"] {
        font-size: $cookie-fontsize !important;
        font-weight: $cookie-fontweight !important;
    }
  #ot-pvcy-txt,
    [id^="ot-header-id"] {
        font-size: $cookie-fontsize !important;
    }
