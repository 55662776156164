.basket-count {
    position: relative;
    margin-right: 10px;
    text-align: center;

    svg {
        display: block;
    }

    &:hover p {
        color: $c-white;
    }
}

    .basket-count__value {
        &:not(:empty) {
            @include apply-map($f-primary);
            @include rem(11px);
            @include lightondark;

            position: absolute;
            top: -50%;
            left: -75%;
            min-width: 22px;
            width: auto;
            height: 22px;
            padding-left: 5px;
            padding-right: 5px;
            font-weight: 600;
            line-height: 23px;
            color: $c-white;
            background: $c-brand;
            border-radius: 22px;
            pointer-events: none;

            .header--fixed & {
                color: $c-white;
            }
        }
    }
