// Some header helpers

// The marker is needed to switch between the static and fixed version of the header
.header-marker {
    position: relative;
}

.header-marker__height {
    position: absolute;
    top: 0;
    left: 0;
}

.header-marker__height--active {
    position: relative;
}

// The main header styles
.header {
    @include allowForBars();

    position: absolute;
    width: 100%;
    z-index: 500;
    background-color: $c-white;
    transition: background-color 0.3s ease;
}

.header--fixed {
    @include allowForBars();

    position: fixed;
    right: 0;
    left: 0;
    background-color: $c-white;
    z-index: 1000;

    .has-notice-bar & {
        top: 0;
    }

    .has-notice-bar.body--admin & {
        top: $admin-height;
    }

    .has-notice-bar.body--debug.body--admin & {
        top: $admin-height + $debug-height;
    }
}

.header--plain {
    + * {
        @include allowForBars((
            default: $nav-height,
            tablet: $nav-height-mobile,
        ), margin-top);
    }

    .has-notice-bar.body--admin & + *,
    .has-notice-bar.body--debug.body--admin & + *,
    .has-notice-bar & + * {
        @include allowForBars((
            default: $nav-height,
            tablet: $nav-height-mobile,
        ), margin-top);
    }
}

.header--clear:not(:hover) {
    @include lightondark;

    background-color: transparent;
    color: $c-white;

    &.header__nav-item--active {
        background-color: $c-white;
    }
}

    .header__top {
        position: relative;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        padding: 25px calc($site-gutter-new / 2) 0;
        border-bottom: 1px solid transparent;

        @include breakpoint(nav) {
            padding: 17px 15px;
        }

        .header--fixed & {
            display: none;

            @include breakpoint(nav) {
                display: flex;

                p,
                a {
                    color: $c-dark;
                }

                svg {
                    fill: $c-dark;
                }
            }
        }

        &.header__top--search {
            background-color: $c-white;
            border-bottom: 1px solid $c-white; //don't remove this, not sure why it's needed but it is!

            svg, p, a {
                fill: $c-dark;
                color: $c-dark;
            }
        }
    }

        .header__left {
            flex: 0 0 33.3%;

            svg {
                display: none;
            }

            @include breakpoint(nav) {
                flex-basis: 20%;
                padding-left: 20px + 20px; //padding + hamburger width

                svg {
                    display: block;
                    fill: $c-dark-grey;
                    transition: fill .3s ease;

                    &:hover {
                        fill: $c-brand;
                    }

                    .header--clear:not(:hover):not(.header__nav-item--active) & {
                        fill: $c-white;
                    }
                }
            }
        }

        .header__logo {
            display: flex;
            justify-content: center;
            flex: 0 0 33.3%;

            @include breakpoint(nav) {
                flex-basis: 60%;
            }

            svg {
                display: block;
                fill: $c-dark-grey;
                transition: fill .3s ease;

                @include breakpoint(laptop) {
                    width: 260px;
                }

                @include breakpoint(nav) {
                    width: 240px;
                }

                @include breakpoint(mobile) {
                    width: 180px;
                }

                .header--clear:not(:hover):not(.header__nav-item--active) & {
                    fill: $c-white;
                }
            }
        }

        .header__logo--fixed {
            position: absolute;
            left: -100%;
            opacity: 0;
            transition: opacity .3s ease;
            display: flex;
            justify-content: flex-start;

            svg {
                fill: $c-dark;
            }

            .header--fixed & {
                top: 50%;
                left: calc($site-gutter-new / 2);
                transform: translateY(-50%);
                opacity: 1;

                svg {
                    width: 51px;
                }

                @include breakpoint(nav) {
                    display: none;
                }
            }
        }

        .header__right {
            flex: 0 0 33.3%;

            @include breakpoint(nav) {
                flex-basis: 20%;
            }
        }
