.flowbox {
    text-align: center;
}

    .flowbox__title {
        @include apply-map($f-title);
        @include font(36px, 43px);

        margin-bottom: 20px;
        color: $c-dark-grey;

        @include breakpoint(tablet) {
            padding: 0 $site-gutter-mobile
        }
    }

    .flowbox__intro {
        @include apply-map($f-primary);
        @include font(16px, 24px);

        color: $c-block-copy;
        margin-bottom: 20px;

        @include breakpoint(tablet) {
            padding: 0 $site-gutter-mobile
        }

        a,
        span {
            font-weight: 600;
            color: $c-dark-grey;
        }
    }

.flowbox--home,
.flowbox--pdp {
    .bzfy-o-container {
        margin-top: 40px;
        max-width: none !important;
    }

    .bzfy-c-carousel-slider-wrapper__inner {
        padding: 0;
    }

    .bzfy-t-feed-carousel {
        #banner-h1 {
            @include apply-map($f-title);
    
            color: $c-dark-grey;
            font-size: 36px !important;
            line-height: 43px !important;
            
            @include breakpoint(tablet) {
                font-size: 24px !important;
                line-height: 29px !important;
            }
        }

        #banner-h2 {
            color: $c-richcopy !important;
        }

        .bzfy-c-carousel-post {
            box-shadow: none;
            margin: 0 auto;
            overflow: visible;
                
            &:hover {
                box-shadow: none;
            }
        }

            .bzfy-o-header {
                position: absolute;
                bottom: -30px;
                left: 0;
                width: 100%;
            }

                .bzfy-o-header__user {
                    @include font(14px, 26px);

                    color: $c-dark;
                    text-align: left;
                    padding: 0;
                    font-weight: 400;

                    &::before {
                        content: '@';
                    }
                }

            .bzfy-o-body {
                display: none;
            }

        .bzfy-c-carousel-control  {
            background-color: transparent !important;

            polygon {
                fill: $c-dark-grey;
                transition: fill .2s ease;
            }
        }

            .bzfy-c-carousel-control {
                top: -50px;
                transform: none;
                right: 20px + 80px;
                left: initial;
                width: 40px;
                height: 40px;

                @include breakpoint(tablet) {
                    right: 35px;
                }

                &:hover {

                    polygon {
                        fill: $c-brand;
                    }
                }
            }

            .bzfy-c-carousel-control--right {
                right: -15px + 80px;

                @include breakpoint(tablet) {
                    right: 0;
                }
            }

        .slick-list {
            overflow: visible !important;
            padding-left: 80px;

            @include breakpoint(tablet) {
                padding-left: 15px;
            }
        }

            .slick-track {
                display: flex;
                font-size: 0;
            }
            

            .slick-slide {
                height: auto;
                outline: none;

                > div {
                    padding: 0 !important;
                    border-right: 1px solid $c-white;
                    height: 100%;
                }
            }

        .bzfy-c-mobile-carousel-control {
            position: absolute;
            top: 0;
            right: 15px;
            width: auto;
            transform: translateY(calc(-100% - 10px));

            .bzfy-c-mobile-circle {
                background: transparent !important;
            }

                .bzfy-c-main-icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    margin: 0;
                }
        }
    } 
}

.flowbox--pdp {
    .bzfy-o-container {
        margin-top: 0;
    }

    .bzfy-feed-banner {
        text-align: left;
        padding: 0 !important;
        padding-left: $site-gutter-new !important;
        margin-bottom: 30px;

        @include breakpoint(tablet) {
            padding-left: $site-gutter-mobile !important;
        }
    }

    .bzfy-feed-banner__tagline {
        margin: 0;
        margin-bottom: 10px;
    }

    .slick-list {
        margin-left: -8px;
    }

    .bzfy-c-carousel-control {
        top: -55px !important;
    }
}

.flowbox--gallery {
    .bzfy-c-tilegrid-post-container {
        padding: 0 !important;
        border-right: 1px solid $c-white;
        border-bottom: 1px solid $c-white;
    }

    .bzfy-c-tilegrid-post__minimalistic-overlay {
        background-color: rgba(29,29,29,0.85) !important;
        color: $c-white !important;
    }

    .bzfy-feed-load-more-button {
        @include apply-map($f-primary);
        @include font(12px, 26px);
        
        padding: 8px 20px !important;
        height: auto !important;
        background: $c-brand !important;
        color: $c-white !important;
        font-weight: 600 !important;
        letter-spacing: 2.5px !important;
        text-transform: uppercase;
    }
}

// Post popup styling for the floxbox--gallery

.bzfy-feed-gate {
    .bzfy-feed-gate-modal__heading--products {
        @include apply-map($f-title);
        @include font(18px, 22px);
    }

    .bzfy-gate-product-image-link {
        @include aspect(1);

        height: 130px;
        width: 130px;
    }

    .bzfy-gate-product-info {
        justify-content: space-between;
    }

    .bzfy-gate-product-name {
        @include apply-map($f-title);
        @include font(18px, 22px);
    }

    .bzfy-gate-product-price {
        @include apply-map($f-primary);
        @include font(12px, 14px);

        color: $c-block-copy;
    }

    .bzfy-gate-product-buy-button {
        @include apply-map($f-primary);
        @include font(12px, 26px);
        
        padding: 8px 20px !important;
        height: auto !important;
        background: $c-brand !important;
        color: $c-white !important;
        font-weight: 600 !important;
        letter-spacing: 2.5px !important;
        text-transform: uppercase;
    }
}
