.quantity {
    display: flex;
    align-items: center;

    .product__group--quantity-price & {
        margin-bottom: 0;
    }
}

    .quantity__controls {
        position: relative;
        height: 30px;
        width: 90px;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        margin-right: 15px;

        input {
            @include apply-map($f-primary);
            @include rem(14px);

            color: $c-dark-grey;
            line-height: 26px;
            padding: 0;
            border: none;
            background: none;
            width: 30px;
            height: 100%;
            text-align: center;
        }
    }

        .quantity__button {
            position: relative;
            background: $c-background;
            border-radius: 50%;
            height: 30px;
            width: 30px;

            svg {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                fill: $c-black;
                transition: fill .3s ease;
            }

            &:hover svg { fill: $c-brand }
        }

        .quantity__unit {
            @include apply-map($f-primary);
            @include rem(12px);

            line-height: 26px;
            color: $c-block-copy;
            position: absolute;
            bottom: -20px;
            left: 50%;
            width: 100%;
            text-align: center;
            transform: translateX(-50%);
        }

    .quantity__copy {
        @include apply-map($f-primary);
        @include rem(14px);

        color: $c-richcopy;
        font-weight: 400;
        margin-left: 10px;

        &::first-letter {
            text-transform: uppercase;
        }

        span {
            color: $c-dark-grey;
            font-weight: 600;
        }

        ~ .product__label {
            margin-left: 5px;
        }
    }
