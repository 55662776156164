.wishlist-count {
    position: relative;
    text-align: center;

    svg {
        display: block;
    }

    &:hover p {
        color: $c-white;
    }
}

.wishlist-count--mobile-nav {
    margin-top: 5px;

    svg {
        fill: none;
        stroke: $c-dark;
    }
}

    .wishlist-count__value {
        &:not(:empty) {
            @include apply-map($f-primary);
            @include rem(11px);
            @include lightondark;

            position: absolute;
            top: -50%;
            left: -75%;
            min-width: 22px;
            width: auto;
            height: 22px;
            padding-left: 5px;
            padding-right: 5px;
            font-weight: 600;
            line-height: 23px;
            color: $c-white;
            background: $c-dark;
            border-radius: 22px;

            .header--fixed & {
                color: $c-white;
            }
        }
    }
