.notice {
    position: relative;
    min-height: 45px;
    padding: 9px 25px;
    background: $c-dark;
    z-index: 9;

    @include breakpoint(phablet) {
        padding: 9px 15px 4px;
        height: 50px;
    }
}

.notice__content {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    margin: 0 auto;
    transition: all 0.5s ease-in;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);

    span {
        color: $c-brand;
    }

    .button {
        margin: 2px 0 0 20px;
    }

    .button--has-icon span {
        justify-content: space-evenly;
    }

    @include breakpoint(phablet) {
        flex-direction: column;
        text-align: center;
    }
}

.notice__content--hidden {
    visibility: hidden;
    opacity: 0;
}

.notice__icon {
    line-height: 16px;

    @include breakpoint(phablet) {
        line-height: auto;
    }

    svg {
        margin-right: 10px;
        height: 21px;
        fill: $c-white;
        line-height: 18px;

        @include breakpoint(phablet) {
            line-height: auto;
        }

        &.icon-delivery-box {
            fill: $c-nav-grey;
        }
    }
}

    .notice__text {
        @include lightondark;

        font-size: 14px;
        color: $c-white;
        line-height: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        letter-spacing: 0.25px;
        position: relative;

        @include breakpoint(phablet) {
            font-size: 12px;
            line-height: 16px;
        }

        &::after {
            content: '';
            position: absolute;
            width: 1px;
            height: 100%;
            background: $c-dark-grey;
            right: -20px;

            @include breakpoint(phablet) {
                display: none;
            }
        }
    }
