p, li, a {
    @include apply-map($f-primary);
}

a {
    color: $f-primary-color;
}

// Titles
@mixin page-title {
    @include apply-map($f-title);
    @include rem(80px);
    color: $f-title-color;
    line-height: 1.2;

    @include breakpoint(tablet) {
        @include rem(60px);
    }

    @include breakpoint(mobile) {
        @include rem(40px);
    }
}

.page-title {
    @include page-title;
}

@mixin large-title {
    @include apply-map($f-title);
    @include rem(60px);
    color: $f-title-color;
    line-height: calc(72 / 60);

    @include breakpoint(tablet) {
        @include rem(44px);
    }

    @include breakpoint(mobile) {
        @include rem(32px);
    }
}

.large-title {
    @include large-title;
}

@mixin medium-title {
    @include apply-map($f-title);
    @include rem(40px);
    color: $f-title-color;

    @include breakpoint(tablet) {
        @include rem(36px);
    }

    @include breakpoint(mobile) {
        @include rem(28px);
    }
}

.medium-title {
    @include medium-title;
}

@mixin small-title {
    @include apply-map($f-title);
    @include rem(24px);
    color: $f-title-color;
}

.small-title {
    @include small-title;
}

@mixin tiny-title {
    @include apply-map($f-title);
    @include rem(20px);
    color: $f-title-color;

    @include breakpoint(mobile) {
        @include rem(18px);
    }
}

.tiny-title {
    @include tiny-title;
}


@mixin panel-title {
    @include apply-map($f-title);
    @include rem(36px);
    color: $f-title-color;
    margin-bottom: 30px;

    @include breakpoint(tablet) {
        @include rem(28px);
    }

    @include breakpoint(mobile) {
        @include rem(26px);
    }
}

.panel-title {
    @include panel-title;
}

// Sub titles
@mixin large-subtitle {
    @include apply-map($f-subtitle);
    @include rem(32px);
    color: $f-subtitle-color;

    @include breakpoint(tablet) {
        @include rem(28px);
    }

    @include breakpoint(mobile) {
        @include rem(24px);
    }
}

.large-subtitle {
    @include large-subtitle;
}

@mixin medium-subtitle {
    @include apply-map($f-subtitle);
    @include rem(24px);
    color: $f-subtitle-color;

    @include breakpoint(mobile) {
        @include rem(20px);
    }
}

.medium-subtitle {
    @include medium-subtitle;
}

@mixin small-subtitle {
    @include apply-map($f-subtitle);
    @include rem(20px);
    color: $f-subtitle-color;

    @include breakpoint(mobile) {
        @include rem(18px);
    }
}

.small-subtitle {
    @include small-subtitle;
}

@mixin tiny-subtitle {
    @include apply-map($f-subtitle);
    @include rem(16px);
    color: $f-subtitle-color;

    @include breakpoint(mobile) {
        @include rem(14px);
    }
}

.tiny-subtitle {
    @include small-subtitle;
}

@mixin smallcaps {
    @include apply-map($f-smallcaps);
    @include rem(12.5px);
}

.smallcaps {
    @include smallcaps;
}

@mixin smallprint {
    @include apply-map($f-smallprint);
    @include rem(14px);
}

.smallprint {
    @include smallprint;
}

@mixin introcopy {
    @include apply-map($f-pagecopy);
    @include rem(24px);
    color: $c-introcopy;
    margin-bottom: 1em;
    @include breakpoint(phablet) {
        @include rem(19px);
    }
}

.introcopy {
    @include introcopy;
}

@mixin bodycopy {
    @include apply-map($f-pagecopy);
    @include rem(16px);
}

.bodycopy {
    @include bodycopy;
}

.column-title {
    @include large-title;

    @include breakpoint(phablet) {
        margin-bottom: 10px;
    }
}

@mixin basket-price {
    @include font(14px, 26px);

    color: $c-richcopy;
    font-weight: 600;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

@mixin narrow-title {
    max-width: 780px;
    margin-left: auto;
    margin-right: auto;

}

.narrow-title {
    @include narrow-title;
}
