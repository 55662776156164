.footer {
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    max-width: 100%;
}

    .footer__top {
        background: $c-dark-grey;
        padding: 30px 0 40px;

        @include breakpoint(phablet) {
            padding: 30px 30px 0;
        }
    }

        .footer__nav {
            margin: 0 40px;

            @include breakpoint(nav) {
                margin-bottom: 20px;
            }

            @include breakpoint(phablet) {
                margin: 0 0px 20px 0;
                padding-right: 15px;
            }
        }

            .footer__nav-list {
                display: flex;
                flex-flow: row wrap;
            }

                .footer__nav-list-item {
                    @include lightondark;

                    flex: 1 0 50%;

                    &:nth-of-type(even) {
                        padding-left: 20px;
                    }

                    a {
                        @include apply-map($f-primary);
                        @include rem(14px);

                        display: inline-block;
                        line-height: 22px;
                        letter-spacing: 0.25px;
                        color: $c-white;
                        cursor: pointer;
                        border-bottom: 1px solid transparent;
                        transition: border-bottom 0.3s ease;

                        &:hover {
                            border-bottom: 1px solid $c-white;
                        }
                    }
                }

        .contact-details {
            margin: 0 40px;
            display: flex;
            flex-flow: row nowrap;
            align-items: center;

            @include breakpoint(nav) {
                margin-bottom: 20px;
            }

            @include breakpoint(phablet) {
                margin: 0 0 40px 0;
                padding-right: 15px;
            }
        }

            .contact-details__wrap {
                display: flex;
                flex-flow: column nowrap;
                align-items: flex-start;
            }

                .contact-details__header {

                    &:first-child {
                        margin-bottom: 20px;

                        @include breakpoint(phablet) {
                            margin-bottom: 10px;
                        }
                    }
                }

                        .contact-details__title {
                            @include apply-map($f-title);

                            color: $c-footer-grey;
                            font-size: 18px;
                            line-height: 22px;
                        }

                .contact-details__content {
                    margin-left: 30px;

                    &:first-child {
                        margin-bottom: 20px;

                        @include breakpoint(phablet) {
                            margin-bottom: 10px;
                        }
                    }
                }

                    .contact-details__link {
                        @include lightondark;

                        font-family: $f-primary-stack;
                        color: $c-white;
                        font-size: 18px;
                        font-weight: 600;
                        letter-spacing: 1.5px;
                        line-height: 26px;
                        text-decoration: none;
                        white-space: nowrap;
                    }

        .footer__trustpilot {
            margin: 0 40px;
            display: flex;
            justify-content: flex-start;

            @include breakpoint(phablet) {
                margin: 0 0 40px 0;
            }
        }

        .signup-form {
            margin: 0 40px 0 0;

            @include breakpoint(tablet) {
                margin: 0 40px;
            }

            @include breakpoint(phablet) {
                margin: 0 0 35px 0;
            }

            button {
                flex-shrink: 0;
            }
        }

            .signup-form__label {
                @include lightondark;

                color: $c-white;
                height: 18px;
                font-family: $f-title-stack;
                font-size: 18px;
                font-style: italic;
                font-weight: bold;
                line-height: 22px;
            }

            .signup-form__field-wrapper {
                margin-top: 5px;
                display: flex;
                align-items: flex-end;
            }

    .footer__bottom {
        display: flex;
        flex-wrap: wrap;
        background: $c-black;
        padding: 20px 40px;

        @include breakpoint(phablet) {
            justify-content: center;
        }

        a {
            @include apply-map($f-primary);
            @include rem(12px);

            line-height: 22px;
            letter-spacing: 0.25px;
            color: $c-white;
            font-weight: 400;
            text-decoration: none;
            border-bottom: 1px solid transparent;
            transition: border-bottom 0.3s ease;
            margin: 0 10px;

            &:first-of-type {
                margin-left: 0;
            }

            &:hover {
                border-bottom: 1px solid $c-white;
            }

            @include breakpoint(phablet) {

                &:first-of-type{
                    margin-left: 10px;
                }
            }
        }

        p {
            @include rem(12px);
            @include apply-map($f-primary);

            line-height: 22px;
            letter-spacing: 0.25px;
            color: $c-white;
            font-weight: 400;
            margin: 0 10px;

            &:last-of-type {
                margin-right: 0;
            }

            @include breakpoint(phablet) {

                &:last-of-type {
                    margin-right: 10px;
                }
            }
        }

        .footer__copyright {
            margin-left: auto;
            color: $c-footer-grey;

            @include breakpoint(phablet) {
                margin-left: 10px;
            }
        }

        #credit-link {
            color: $c-footer-grey;

            a {
                display: inline-block;
            }
        }
    }

// ******* BELOW STYLING FOR OLD FOOTER ON CHECKOUT PAGE ******** //

footer {
    background: $c-dark-grey;
    color: $c-white;

    a {
        color: $c-white;
        text-decoration: none;
    }
}


    .footer-section--legal-links {
        padding: 17px 0 18px;
        border-top: 1px solid rgba($c-white, 0.2);

        @include breakpoint(tablet) {
            padding: 0;
        }
    }

        .footer-bottom {
            position: relative;

            @include breakpoint(tablet) {
                display: flex;
                flex-direction: column;
            }
        }

            .footer-bottom__group {}

            .footer-bottom__group--address {

                @include breakpoint(tablet) {
                    order: 2;
                    padding-bottom: 69px;
                }

                @include breakpoint(tablet) {
                    border-top: 1px solid rgba($c-white, 0.2);
                }
            }

            .footer-bottom__group--payment {
                font-size: 0;
                text-align: center;

                @include breakpoint(tablet) {
                    margin: 15px auto;
                    order: 1;
                }

                svg {
                    display: inline-block;
                    vertical-align: middle;
                    width: 25px;
                    height: 16px;
                }

                svg + svg {
                    margin: 0 0 0 5px;
                }

                .icon-sagepay-with-text + svg {
                    margin: 0 0 0 15px;
                }

                .icon-sagepay-with-text {
                    width: 80px;
                    height: 28px;
                    fill: $c-white;
                }
            }

            .footer-bottom__group--trustpilot {
                .trustpilot-widget {
                    display: block;
                    margin: 0 auto;
                    padding-left: 7px;
                }

                @include breakpoint(phablet) {
                    display: inline-block;
                    padding: 0 0 30px 0;
                    margin: 0;
                }
            }

            .footer-bottom_order-1 {
                order: 1;

                @include breakpoint(phablet) {
                    order: 0;
                }
            }

            .footer-bottom_order-2 {
                order: 2;

                @include breakpoint(phablet) {
                    order: 0;
                }
            }

            .footer-bottom_order-3 {
                order: 3;

                @include breakpoint(phablet) {
                    order: 0;
                }
            }

            .footer-bottom__group--legal-links {

                @include breakpoint(tablet) {
                    order: 0;
                }
            }

                .footer-bottom__copyright {

                    @include breakpoint(tablet) {
                        position: absolute;
                        right: 0;
                        bottom: 0;
                        left: 0;
                        border-top: 1px solid rgba($c-white, 0.2);
                    }
                }


        .footer-bottom__address,
        .footer-bottom__copyright {

            @include breakpoint(tablet) {
                display: block;
                margin: 0 auto;
                padding: 16px 0 20px;
                text-align: center;
            }

            p {
                @include rem(12px);

                line-height: calc(14 / 12);
                margin-bottom: 5px;

                &:last-child {
                    margin-bottom: 0;
                }
            }

            span {

                @include breakpoint(tablet) {
                    display: block;
                }
            }

            span + span {

                @include breakpoint(tablet) {
                    margin-top: 5px;
                }
            }

            a {
                line-height: inherit;
            }
        }

        .footer-legal-links {
            font-size: 0;
            margin-bottom: 5px;

            @include breakpoint(tablet) {
                display: block;
                margin: 0 -10px;
                padding: 16px 10px 18px;
                border-bottom: 1px solid rgba($c-white, 0.2);
            }
        }

            .footer-legal-links__item {
                display: inline-block;

                a {
                    @include rem(12px);

                    line-height: calc(14 / 12);
                }

                &:not(:last-child) {

                    a:after {
                        content: '|';
                        margin-right: 5px;
                        margin-left: 5px;
                        color: rgba($c-white, 0.2);
                    }
                }
            }

        .footer-page-links {
            font-size: 0;
            padding: 40px 10px;
            margin: 0;

            @include breakpoint(phablet) {
                display: block;
                text-align: center;
                padding: 10px 10px;
            }

            &:first-of-type {

                @include breakpoint(tablet) {
                    float: none;
                }
            }
        }

        .footer-bottom__group--showrooms {
            @include breakpoint(phablet) {
                border-bottom: 1px solid rgba($c-white, 0.2);
                padding-bottom: 10px;
            }
        }

            .footer-page-links__item {
                display: inline-block;

                a {
                    @include rem(14px);
                    line-height: calc(16 / 14);
                }

                &:not(:first-of-type) {
                    a:before {
                        content: '|';
                        margin-right: 15px;
                        margin-left: 15px;
                        color: rgba($c-white, 0.2);
                    }
                }

                a:before {
                    @include breakpoint(tablet) {
                        content: '';
                    }
                }
            }
