.wishlist {
    margin: 20px 0 80px;

    @include breakpoint(tablet) {
        margin-top: 20px;
        margin-bottom: 40px;
    }
}

.wishlist__header {
    @include breakpoint(phablet) {
        padding: 0 $site-gutter-mobile;
    }
}

.wishlist__title {
    @include apply-map($f-title);
    @include font(44px, 53px);

    color: $c-dark;
    margin-bottom: 20px;

    @include breakpoint(tablet) {
        @include font(32px, 38px);
    }
}

.wishlist__title--centre {
    text-align: center
}

.wishlist__title--med {
    @include font(36px, 43px);

    margin-bottom: 30px;

    @include breakpoint(tablet) {
        @include font(28px, 34px);
    }
}

.wishlist__title--small {
    @include font(22px, 26px);

    margin-bottom: 15px;

    @include breakpoint(tablet) {
        @include font(18px, 22px);
    }
}

.wishlist__copy {
    @include font(16px, 20px);

    color: $c-block-copy;
    margin-bottom: 30px;

    @include breakpoint(tablet) {
        @include font(14px, 22px);
    }
}

.wishlist__copy--centre {
    text-align: center;
}

.wishlist__copy--mb {
    margin-bottom: 20px;
}

.wishlist__placeholder {
    margin: 60px 0;
    display: flex;

    @include breakpoint(tablet) {
        flex-direction: column;
        margin: 35px 0;
    }
}

.wishlist__image {
    @include aspect(1);
    order: 1;

    img {
        max-width: 100%;
        height: auto;
    }

    @include breakpoint(tablet) {
        margin: 0 -15px;
        order: 2;
    }
}

.wishlist__content {
    padding: 20px 80px;
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    background: $c-background;
    order: 2;

    @include breakpoint(tablet) {
        margin: 0 -15px;
        padding: 40px $site-gutter-mobile;
        order: 1;
    }
}

.wishlist__cta {}

.wishlist__cta--mb {
    margin-bottom: 40px;
}

.wishlist__item-grid {

}

.wishlist__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    @include breakpoint(tablet) {
        margin-bottom: 27px;
    }
}

.wishlist__showing {
    @include font(14px, 20px);

    color: $c-block-copy;

    @include breakpoint(tablet) {
        @include font(12px, 20px);
    }
}

.wishlist__grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    column-gap: 20px;
    row-gap: 25px;
    padding-bottom: 35px;
    margin-bottom: 50px;
    border-bottom: 1px solid $c-filter-grey;

    @include breakpoint(tablet) {
        grid-template-columns: 1fr;
        border-bottom: none;
        margin-bottom: 0;
    }
}

.wishlist__button {
    text-align: center;

    button {
        @include breakpoint(tablet) {
            width: 100%;
        }
    }
}
