.inspiration {
    margin-top: 40px;
    margin-bottom: 135px;

    @include breakpoint(phablet) {
        margin-bottom: 60px;
    }
}

    .inspiration__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        
        a {
            margin-bottom: 40px;

            @include breakpoint(tablet) {
                margin-bottom: 30px;
            }
        }

        @include breakpoint(phablet) {
            align-items: flex-start;
        }
    }

        .inspiration__header {
            margin-bottom: 25px;

            @include breakpoint(tablet) {
                margin-bottom: 10px;
            }
        }
    
            .inspiration__title {
                @include apply-map($f-title);
                @include rem(44px);
    
                line-height: 53px;
                color: $c-dark-grey;

                @include breakpoint(tablet) {
                    @include rem(32px);

                    line-height: 38px;
                }
            }
    
            .inspiration__copy {
                @include rem(16px);
                @include apply-map($f-primary);
    
                line-height: 24px;
                color: $c-block-copy;
                margin-bottom: 15px;
                text-align: center;

                @include breakpoint(tablet) {
                    @include rem(14px);

                    line-height: 22px;
                    text-align: left;
                }
            }

           
    

    