.outdoor-radio__wrap {
    display: flex;
    flex-wrap: wrap;
}

    .outdoor-radio {
        @include rem(14px);

        display: flex;
        align-items: center;
        color: $c-dark-grey;
        line-height: 16px;
        margin-right: 15px;

        &:hover {
            cursor: pointer;
        }
    }

        .outdoor-radio__input {
            display: flex;

            input {
                opacity: 0;
                width: 0;
                height: 0;

                &:checked + .outdoor-radio__control {
                    background: radial-gradient($c-dark-grey 50%, rgba(255, 0, 0, 0) 51%);
                }
            }
        }

        .outdoor-radio__control {
            background: $c-white;
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid $c-dark-grey
        }

        .outdoor-radio__label {
            line-height: 1;
            margin-left: 10px;
        }

    .outdoor-radio__text {
        @include rem(14px);

        color: $c-dark-grey;
        line-height: 22px;
        flex-basis: 100%;
        font-weight: 600;
        margin-bottom: 10px;
    }