.quote-carousel {
    position: absolute;
    top:50%; 
    transform:translateY(-50%);
    left: 90px;
    right: 0;
    width: 50%;
    z-index: 10;

    @include breakpoint(laptop) {
        left: 40px;
    }

    @include breakpoint(phablet) {
        @include unsetAbsolute;

        top: 130px;
        right: 25px;
        left: 25px;
        bottom: 55px;
        width: auto;
    }
}

    .quote-carousel__marks {
        position: absolute;
        top: -40px;
        left: -40px;
        
        svg {
            fill: $c-white;
            opacity: 0.04;
        }

        @include breakpoint(laptop) {
            left: -20px;
            top: -70px;
        }

        @include breakpoint(tablet) {
            top: -90px;
        }

        @include breakpoint(phablet) {
            left: -10px;
            top: -110px;
            
            svg {
                width: 130px;
            }
        }
    }

    .quote-carousel__content {}

        .quote-carousel__title {
            @include apply-map($f-primary-light);
            @include rem(34px);

            line-height: 44px;
            color: $c-white;
            margin-bottom: 20px;

            span {
                font-weight: 600;
            }

            @include breakpoint(tablet) {
                @include rem(28px);

                line-height: 36px;
            }

            @include breakpoint(phablet) {
                @include rem(24px);

                line-height: 32px;
            }
        }

        .quote-carousel__author {
            @include apply-map($f-title);
            @include rem(16px);

            line-height: 19px;
            color: $c-nav-border;
        }