@use "sass:math";

.home-callout {
    margin: 0 40px 80px 0;

    @include breakpoint(tablet) {
        margin: 0 0 40px 0;
    }
}

.home-callout--right {
    margin: 0 0 80px 40px;

    @include breakpoint(tablet) {
        margin: 0 0 60px 0;
    }
}

    .home-callout__image-link {}

        .home-callout__image {
            @include aspect(math.div(5, 3));

            margin-bottom: 35px;

            @include breakpoint(phablet) {
                margin-bottom: 20px
            }
        }

    .home-callout__title {
        @include apply-map($f-title);
        @include rem(28px);

        line-height: 34px;
        margin-bottom: 15px;
        color: $c-dark-grey;

        @include breakpoint(tablet) {
            @include rem(24px);

            line-height: 29px;
        }
    }

    .home-callout__copy {
        @include apply-map($f-primary);
        @include rem(16px);

        line-height: 24px;
        color: $c-block-copy;
        width: 75%;
        margin-bottom: 6px;

        @include breakpoint(tablet) {
            @include rem(14px);

            line-height: 22px;
            width: 100%;
        }
    }
