.pagination {
    text-align: center;
    margin: 40px 0;
    position: relative;

    .button {
        position: relative;
        background: transparent;
        border: none;
        border-bottom: 1px solid $c-filter-grey;
        margin: 0 5px;
        transition: border .2s ease;
 
        span {
            @include rem(12px);
            @include apply-map($f-primary);

            padding: 0 20px 10px;
            color: $c-dark-grey;
            letter-spacing: 2.5px;
            font-weight: 600;

            svg {
                margin: 0;
                padding: 0;
                fill: $c-brand;
            }
        }

        &::after {
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 0;
            height: 1px;
            transition: width 0.6s cubic-bezier(0.61, 1, 0.88, 1);
            z-index: 5;
        }
    
        &:hover,
        &:active {
            background: none;
            border: none;
            border-bottom: 1px solid $c-filter-grey;
        }
    
        &:hover::after {
            width: 100%;
            background: $c-brand;
        }

        &[disabled],
        &--disabled {
            background: transparent;
            border: none;
            border-bottom: 1px solid $c-filter-grey;
            cursor: default;
            pointer-events: none;

            svg {
                opacity: .3;
            }

            a:hover &,
            a:active &,
            &:hover,
            &:active {
                background: transparent;
                border-color: $c-dark-grey;
            }
        }
    }
}

.pagination__prev-icon {
    
    svg {
        transform: rotate(180deg);
    }
}

    .pagination__wrap {
        position: relative;
        z-index: 1;
        display: flex;
        justify-content: center;
        background-color: $c-white;
    }

    .pagination__links {
        display: flex;
    }

    .pagination__link--mobile {
        display: none;

        @include breakpoint(phablet) {
            display: block;
        }
    }

    .pagination__gap {
        padding-top: 5px;
    }

    .pagination__link--active {
        
        &.button {
            border-bottom: 1px solid $c-dark-grey;
        }
    }