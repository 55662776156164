.social {
    display: flex;
    align-items: center;

    a {
        display: flex;
        align-items: center;
        justify-content: center;
        
        &:not(:first-of-type) {
            margin-left: 27px;
        }

        &:hover {

            svg {
                fill: $c-brand;
            }
        }
    }

    svg {
        fill: $c-white;
        transition: fill 0.3s ease;
    }
}