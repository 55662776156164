.sticky {
    @include allowForBars($sticky-top);

    position: sticky;
    transition: top .3s;
    z-index: 2;

    @include breakpoint(tablet) {
        @include allowForBars($sticky-top-mobile);
    }

    .has-notice-bar & {
        top: $nav-height--fixed;
    }

    .has-notice-bar.body--admin & {
        top: $admin-height + $nav-height--fixed;
    }
}

.sticky--flush {
    position: sticky;
    transition: top .3s;
    z-index: 2;
    top: 0;

    .has-notice-bar & {
        top: 0;
    }

    .has-notice-bar.body--admin & {
        top: 0;
    }
}

.sticky--tight {
    @include allowForBars($sticky-top-tight);

    @include breakpoint(tablet) {
        @include allowForBars($sticky-top-mobile-tight);
    }
}

.sticky--semi-tight {
    @include allowForBars($sticky-top-semi-tight);

    @include breakpoint(tablet) {
        @include allowForBars($sticky-top-mobile-semi-tight);
    }
}

.sticky--loose {
    @include allowForBars($sticky-top-loose);

    @include breakpoint(tablet) {
        @include allowForBars($sticky-top-mobile-loose);
    }
}

.sticky--hide {
    @include allowForBars(-$sticky-top);
    @include breakpoint(tablet) {
        @include allowForBars($sticky-top-mobile);
    }
}
