@use 'sass:math';

.hero {
    position: relative;
    background: $c-dark-grey;
    height: 100vw * calc(3 / 4);
    max-height: calc(100vh - 45px);

    @include breakpoint(full) {
        height: 100vw * calc(9 / 16);
    }

    @include breakpoint(phablet) {
        height: auto;
        max-height: none;
    }
}

.hero--categories {
    @include aspect(math.div(7, 2));

    height: auto;

    @include breakpoint(tablet) {
        @include aspect(math.div(7, 2));
        background-color: transparent;
    }
}

.hero__inner {
    position: relative;
}

.hero__item {
    display: none;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 200px;
        opacity: 0.4;
        background: linear-gradient(180deg, $c-black 0%, rgba(0,0,0,0) 100%);
    }

    .hero--categories & {
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            width: 50%;
            opacity: 0.4;
            background: linear-gradient(90deg, $c-black 0%, rgba(0,0,0,0) 100%);
        }
    }
}

.hero__item:first-of-type {
    display: block
}

    .hero__img {
        height: 100vw * calc(3 / 4);
        max-height: calc(100vh - 45px);

        @include breakpoint(full) {
            height: calc(100vw * (9 / 16));
        }

        @include breakpoint(phablet) {
            @include aspect(1);

            max-height: none;
        }

        img {
            @include coverImage;
            width: 100%;
            height: 100%;
        }

        .hero--categories & {
            height: auto;

            @include breakpoint(phablet) {
                @include aspect(math.div(5, 4));
            }
        }
    }

    .hero__img--zoom {
        overflow: hidden;
        position: relative;

        img {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            @keyframes slowZoom {
                from {
                    transform: translate(-50%, -50%), scale(1);
                }

                to {
                    transform: translate(-50%, -50%), scale(1.1);
                }
            }

            animation: slowZoom linear 23s 1 forwards;
        }
    }

    .hero__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
        background: linear-gradient(180deg, rgba($c-black, 0) 0%, $c-black 100%);
    
        @include breakpoint(phablet) {
            height: 150px;
            background: linear-gradient(0deg, rgba($c-black, 0) 0%, $c-black 100%);
        }

        .hero--categories & {
            top: unset;
            bottom: 0;
            background: linear-gradient(0deg, rgba($c-black, 1) 0%, rgba($c-black, 0) 100%);
            height: 60%;
            opacity: 0.5;
        }
    }

    .hero__content {
        position: absolute;
        width: 45%;
        left: $site-gutter-new;
        bottom: 80px;

        @include breakpoint(tablet) {
            width: 75%;
            left: calc($site-gutter-new / 2);
            bottom: calc($site-gutter-new / 2);
        }

        @include breakpoint(phablet) {
            width: 100%;
            position: relative;
            padding: 25px $site-gutter-mobile 40px;
            left: initial;
            bottom: initial;
            background-color: $c-dark;
        }

        .hero--categories & {
            width: 75%;
            bottom: 30px;

            @include breakpoint(phablet) {
                position: absolute;
                background-color: transparent;
                left: $site-gutter-mobile;
                right: $site-gutter-mobile;
                padding: 0;
                bottom: 20px;
                width: auto;
            }
        }
    }

        .hero__title {
            @include lightondark;
            @include apply-map($f-title);
            @include font(50px, 60px);

            margin-bottom: 15px;
            color: $c-white;
            text-shadow: 0 4px 15px rgba(0,0,0,0.4);

            @include breakpoint(phablet) {
                @include font(32px, 38px);

                text-shadow: none;
                margin-bottom: 20px;
            }

            .hero--categories & {
                @include font(44px, 53px);

                margin-bottom: 10px;

                @include breakpoint(tablet) {
                    margin-bottom: 0;
                }

                @include breakpoint(phablet) {
                    @include font(32px, 38px);
                }
            }
        }

        .hero__text {
            @include lightondark;
            @include font(18px, 30px);

            color: $c-white;
            letter-spacing: 0.25px;
            text-shadow: 0 4px 15px rgba(0,0,0,0.4);

            @include breakpoint(phablet) {
                @include font(14px, 22px);

                text-shadow: none;
                margin-bottom: 25px;
            }

            a {
                color: $c-white;
            }

            .hero--categories & {
                font-weight: 600;
                margin-bottom: 0;

                @include breakpoint(tablet) {
                    color: $c-richcopy;
                    text-shadow: none;
                    font-weight: 400;
                    margin-top: 20px;
                    margin-bottom: 10px;
                    padding-left: 40px - $site-gutter-mobile;
                    padding-right: 40px - $site-gutter-mobile;
                }

                @include breakpoint(phablet) {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }

        .hero__button {
            margin-top: 30px;

            .hero--categories & {
                margin-top: 20px;
            }
        }
