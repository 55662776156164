.overlay-reviews {
    margin-bottom: 50px;
    margin-right: calc(((100vw - 100%) / 2) * -1);

    @include breakpoint(tablet) {
        margin-left: calc(((100vw - 100%) / 2) * -1);
        margin-right: calc(((100vw - 100%) / 2) * -1);
        margin-bottom: 20px;
    }

    @include breakpoint(phablet) {
        margin-left: -$site-gutter-mobile;
        margin-right: -$site-gutter-mobile;
    }
}

    .overlay-reviews__header {
        flex-basis: 100%;
        margin-bottom: 30px;
        position: relative;

        @include breakpoint(phablet) {
            margin-bottom: 25px;
        }
    }

        .overlay-reviews__title {
            @include apply-map($f-title);
            @include rem(28px);

            line-height: 34px;
            color: $c-dark-grey;
            text-align: center;

            @include breakpoint(phablet) {
                text-align: left;
            }
        }

    .overlay-reviews__callout {
        display: flex;
        flex-wrap: nowrap;

        @include breakpoint(tablet) {
            flex-wrap: wrap;
        }
    }

    .overlay-reviews__main {
        flex-basis: 85%;
        position: relative;

        @include breakpoint (tablet) {
            flex-basis: 100%;
        }
    }

        .overlay-reviews__img {
            position: relative;
            height: 330px;

            &::after {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(270deg, #167153 0%, rgba(22,113,83,0) 100%);
                z-index: 5;
            }

            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #076C4B;
                opacity: .7;
                z-index: 5;
            }

            img {
                height: 100%;
                @include objectFitImage;
            }

            @include breakpoint(phablet) {
                height: 240px;
            }
        }

        .overlay-reviews__container {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 0;
            left: 0;
            width: 100%;
            padding: 20px 65px;
            z-index: 100;

            @include breakpoint(tablet) {
                padding: 20px 30px;
            }
        }

            .overlay-reviews__content {
                padding: 0px 20px;
            }

                .overlay-reviews__stars {
                    display: flex;
                    flex-wrap: nowrap;
                    margin-bottom: 13px;

                    svg {
                        width: 21px;

                        &:not(:first-of-type) {
                            margin-left: 2px;
                        }
                    }
                }

                .overlay-reviews__copy {
                    @include apply-map($f-primary);
                    @include rem(14px);

                    line-height: 22px;
                    color: $c-white;
                    margin-bottom: 10px;
                }

                .overlay-reviews__author {
                    @include apply-map($f-title);
                    @include rem(16px);

                    line-height: 19px;
                    color: $c-reviews;
                }

    .overlay-reviews__side {
        flex-basis: 15%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-right: 40px;

        @include breakpoint(tablet) {
            flex-basis: 100%;
            justify-content: center;
            margin-right: 0;
            order: 2;
            margin-top: 35px;
        }

        .overlay-reviews__side__string {
            @include apply-map($f-primary);
            @include rem(18px);

            text-transform: uppercase;
            line-height: 26px;
            letter-spacing: 3px;
            font-weight: 600;
            color: $c-dark-grey;
            margin-bottom: 10px;
        }

        .overlay-reviews__side__total {
            @include rem(14px);

            color: $c-block-copy;
            line-height: 22px;
            margin: 10px 0 5px;
            font-weight: 400;
            white-space: nowrap;

            span {
                font-weight: 600;
                color: $c-dark-grey;
            }
        }

        .overlay-reviews__tmp {

            @include breakpoint(tablet) {
                width: 25%;
            }

            @include breakpoint(phablet) {
                width: 35%;
            }

            @include breakpoint(mobile) {
                width: 50%;
            }
        }

        .overlay-reviews__side__logo {
            width: 60%;

            @include breakpoint(tablet) {
                width: 15%;
            }

            @include breakpoint(phablet) {
                width: 25%;
            }

            @include breakpoint(mobile) {
                width: 30%;
            }
        }
    }
