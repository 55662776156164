// $cookie-fontcolor: $c-body;
// $cookie-altfontcolor: $c-secondary;
// $cookie-fontsize: .875rem;
// $cookie-fontweight: normal;
// $cookie-bg: #ECEAE6;
// $cookie-radius: 0px;
// $cookie-fontcolor: #bebebe;
// $cookie-copy-fontsize: 14px;
// $cookie-lineheight: 1.71429;
// $cookie-fontweight: 400;
// $cookie-fg: $c-white;
// $cookie-secondary: $c-button;
// $cookie-footer-bg: $c-background;
// $cookie-padding: 10px;
// $cookie-overlay: rgba(0,0,0,0.4);
// $cookie-switch-height: 30px;
$cookie-switch-bg: #FFF;
$cookie-switch-fg: #FE5000;
// $cookie-border: $c-border;
$cookie-btn-texttransform: uppercase;
$cookie-btn-letterspacing: 2.5px;
$cookie-btn-fontsize: 12px;
$cookie-btn: $c-white;
$cookie-btn-bg: $c-brand;
// $cookie-btn-onhover: $c-white;
$cookie-btn-onhover-bg: $c-black;
$cookie-btn-fontweight: 700;
$cookie-wrappadding: 20px 40px;
$cookie-wrappadding-mobile: 15px;
// $cookie-banner-maxwidth:

@import "cookie-centre/cookie-settings";
@import "cookie-centre/cookie-onetrust";


// Buttons
#onetrust-accept-btn-handler,
#onetrust-reject-all-handler,
#accept-recommended-btn-handler,
.save-preference-btn-handler,
.ot-pc-refuse-all-handler,
#ot-sdk-btn {
    @include lightondark;
    line-height: calc(26 / 12) !important;
    padding: 10px 18px !important;
    transition: background-color .3s ease, color .3s ease, border-color .3s ease !important;

    // font-weight: normal;
    // font-family: Montserrat-SemiBold, Calibri, sans-serif !important;
    -webkit-font-smoothing: antialiased !important;
}

// Banner
#onetrust-banner-sdk {
    .ot-optout-signal {
        span {
            color: #BEBEBE;
        }

        max-width: 33%;

        @include breakpoint(nav-squeeze) {
            max-width: 50%;
        }

        @include breakpoint(tablet) {
            max-width: 100%;
        }
    }
}

#onetrust-banner-sdk  .ot-sdk-row {
    margin-left: auto !important;
    margin-right: auto !important;
}

#onetrust-banner-sdk #onetrust-policy-text {
    padding-right: 25px !important;

    @include breakpoint(tablet) {
        padding-right: 0 !important;
    }
}

#onetrust-banner-sdk {
    #onetrust-policy-text {
        -webkit-font-smoothing: antialiased !important;
    }
}

#onetrust-pc-btn-handler.cookie-setting-link {
    font-weight: 400 !important;
}


// Preferences
.otFlat #onetrust-button-group-parent {
    padding-right: 0 !important;
}
#onetrust-pc-sdk #ot-pc-content .ot-tgl .ot-switch .ot-switch-nob {
    border-color: #b8b8b8 !important;
    background-color: #f5f5f5 !important;
}

// Audit
.cookie-audit__audit {
    min-height: 100vh;
}

