.block--new-split-feature {
    padding: 0;
    margin: 40px 0;

    &:first-child {
        margin-top: 0;
    }

    &.block--reverse {
        .block__image,
        .block__carousel {
            order: -1;
        }

        .block__subtitle {
            margin-left: 60px;

            @include breakpoint(tablet) {
                margin: 0;
            }
        }

        .block__content {
            padding: 60px $site-gutter-new 60px 0;

            @include breakpoint(tablet) {
                padding: 35px $site-gutter-mobile;
            }
        }

        .block__title {
            margin-right: 0;
            margin-left: -120px;

            @include breakpoint(tablet) {
                margin: 0;
            }
        }

        .block__carousel {
            margin-right: 0;
            margin-left: 80px;

            @include breakpoint(tablet) {
                margin: 0;
            }
        }

        .block__body {
            padding-left: 45px;
            padding-right: 0;
            margin-left: 60px;

            @include breakpoint(tablet) {
                margin-left: 0;
                padding-left: 25px;
                padding-right: 0;
            }
        }

        .block__cta {
            padding-left: 60px;

            @include breakpoint(tablet) {
                padding: 0;
            }
        }
    }

    .block__grid {
        display: grid;
        grid-template-columns: repeat(2, minmax(0, 1fr));
        align-items: center;

        @include breakpoint(tablet) {
            grid-template-columns: 1fr;
        }
    }

        .block__image {
            aspect-ratio: 5 / 3;
            background-color: $c-black;
            align-self: stretch;

            @include breakpoint(tablet) {
                aspect-ratio: 9 / 4;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                opacity: 0.85;
            }
        }

        .block__content {
            padding: 60px 0 60px $site-gutter-new;

            @include breakpoint(tablet) {
                padding: 35px $site-gutter-mobile;
                max-width: $tab-max-width;
                margin-left: auto;
                margin-right: auto;
            }
        }

            .block__title {
                @include apply-map($f-title);
                @include font(100px, 100px);

                color: var(--block-text-colour);
                text-align: left;
                margin-right: -120px;
                position: relative;
                margin-top: 0;
                margin-bottom: 15px;
                z-index: 1;
                text-indent: 50px;
                min-width: 740px;

                @include breakpoint(tablet) {
                    @include font(50px, 50px);

                    text-align: center;
                    min-width: 100%;
                    margin-right: 0;
                    text-indent: 0;
                    margin-bottom: 10px;
                }
            }

            .block__title--large {
                max-width: 500px;

                @include breakpoint(tablet) {
                    @include font(40px, 40px);
                }
            }

            .block__title--small {
                @include font(80px, 80px);

                text-indent: 0;
                margin-bottom: 30px;

                @include breakpoint(tablet) {
                    @include font(40px, 40px);

                    position: absolute;
                    top: 25px;
                    max-width: 400px;
                    margin: 0 auto;
                    min-width: 0;
                    left: 0;
                    right: 0;
                }
            }

            .block__subtitle {
                @include semiboldText;
                @include font(18px, 28px);

                color: var(--block-text-colour);

                @include breakpoint(tablet) {
                    @include font(18px, 24px);

                    text-align: center;
                }
            }

            .block__body {
                padding-left: 45px;
                padding-right: 60px;
                border-left: 1px solid var(--block-text-colour);
                color: var(--block-text-colour);
                margin-bottom: 30px;

                @include breakpoint(tablet) {
                    padding-left: 25px;
                    padding-right: 0;
                }
            }

            .block__cta {
                padding: 0;
                background-color: transparent;
            }

        .block__carousel {
            margin-right: 80px;
            min-width: 0;
            height: 100%;

            @include breakpoint(tablet) {
                order: -1;
                margin: 0;
                padding-top: 130px;
            }

            & * {
                height: 100%;
            }
        }

            .block__carousel-image {
                aspect-ratio: 6 / 5;
            }
}
