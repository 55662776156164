.batches {
    margin-bottom: 5px;

    .tooltip {
        margin: 0 0 5px 5px;
        .tooltip__icon {
            @include rem(11px);
            width: 15px;
            height: 15px;
            padding-left: 4px;
        }
    }
}

.batches__header {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
}

    .batches__title {
        @include apply-map($f-primary);
        @include rem(14px);

        line-height: 22px;
        color: $c-dark-grey;
        font-weight: 600;
        margin-bottom: 5px;
        display: inline-block;
    }

.batches__main {
    background: $c-background;
}

.batches__selector {
    padding: 15px;
    border-bottom: 1px solid $c-white;

    .select-replace {
        width: 60%;
        height: 45px;

        .select-replace__value {
            @include rem(14px);

            border: none;
        }
    }

    .batches__total {
        @include apply-map($f-primary);
        @include rem(14px);

        line-height: 22px;
        color: $c-dark-grey;
        font-weight: 600;
        width: 40%;
        text-align: right;
        line-height: 44px;
    }

}

.batches__pieces {
    padding: 15px;
    display: none;

    .batches__piece {
        display: flex;
        align-items: center;
        margin-bottom: 5px;
    }

    .batches__piece-info {
        line-height: 30px;
    }

    .batches__piece-info--alt {
        margin: 15px -15px 0;
        padding: 15px 15px 0;
        border-top: 1px solid $c-white;
    }

    .batches__piece-qty {
        position: relative;
        margin-right: 10px;

        input {
            width: 40px;
            height: 30px;
            margin: 0 30px;
            padding: 0;
            text-align: center;
            background: transparent;
            border: none;

            &:focus {
                box-shadow: none;
            }
        }

        a  {
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            top: 50%;
            width: 30px;
            height: 30px;
            background: $c-white;
            border-radius: 100%;
            transform: translateY(-50%);

            &.product__qty__minus {
                left: 0;
            }

            &.product__qty__plus {
                right: 0;
            }
        }
    }
}

.batches__pieces-show {
    display:block;
}

.batches__purchase-options {
    margin: 20px 0 40px 0;

    .field {
        margin: 20px 10px;

        .check_box { display: flex; }
    }

    & .select-replace {
        width: 50%;
        height: 45px;
    }

    & .check-replace {
        margin-top: 3px;
    }

    & .tooltip .tooltip__icon {
        margin: 8px 0 0 2px;
        color: $c-filter-expand;
    }
}
.batches__footer {
    display: flex;
    flex-direction: column;
    margin: 40px -10px 0;

    > * {
        margin-right: 10px;
        margin-left: 10px;
    }

    .batches__totals {

        .batches__totals-main {
            @include apply-map($f-primary);
            @include rem(18px);

            line-height: calc(26 / 18);
            color: $c-block-copy;

            @include breakpoint(mobile) {
                @include rem(15px);
            }

            span {
                @include rem(28px);

                line-height: calc(33 / 28);
                font-weight: bold;
                color: $c-dark-grey;
                margin: 0 10px;
            }
        }

        .batches__totals-info {
            @include rem(14px);
            line-height: 14px;

            @include breakpoint(mobile) {
                @include rem(12px);
                line-height: 12px;
            }

        }
    }
    .batches__actions {

        .button {
            margin: 0;

            span {
                @include rem(12px);
            }
        }
    }
}

.batches__footer--fabric {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;

    @include breakpoint(tablet) {
        justify-content: flex-start;
    }

    @include breakpoint(small-mobile) {
        align-items: inherit;
        flex-direction: column;
    }
}

.reserve-cutting__text.check_box__text {

    .field label.check_box & {
        @include rem(14px);
        @include apply-map($f-primary);

        font-weight: 400;
        line-height: 26px;
        color: $c-block-copy;

        @include breakpoint(mobile) {
            @include rem(13px);
            line-height: 14px;
        }
    }


    .reserve-cutting__text-no-cutting,
    .reserve-cutting__text-cutting {
        @include rem(12px);
        @include apply-map($f-primary);

        color: $c-block-copy;
        font-weight: 400;
        display: block;
    }

    .reserve-cutting__text-hidden {
        display: none;
    }
}

.batches-radio__wrap {
    display: flex;
    flex-wrap: wrap;

    .check_box {
        display: flex;
    }
}

    .batches-radio {
        @include rem(14px);

        display: flex;
        align-items: center;
        color: $c-dark-grey;
        line-height: 16px;
        margin-right: 15px;

        &:hover {
            cursor: pointer;
        }
    }

        .batches-radio__input {
            display: flex;

            input {
                opacity: 0;
                width: 0;
                height: 0;

                &:checked + .batches-radio__control {
                    background: radial-gradient($c-dark-grey 50%, rgba(255, 0, 0, 0) 51%);
                }
            }
        }

        .batches-radio__control {
            background: $c-white;
            display: block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            border: 1px solid $c-dark-grey
        }

        .batches-radio__label {
            line-height: 1;
            margin-left: 10px;
        }
