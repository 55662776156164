@font-face {
    font-family:"Bodoni W01 LT_i7";
    src:url("/fonts/8e76616d-ce24-4651-8581-84dcc7ae3751.eot?#iefix") format("eot");
    font-display: swap;
}
@font-face{
    font-family:"BT Bauer Bodoni W01";
    src:url("/fonts/ab8e2266-1283-40a2-9b06-b06d42e02774.woff2") format("woff2"),url("/fonts/b44c1217-3a78-45e2-8faf-6c8bc77dff2d.woff") format("woff");
font-weight: 700;
font-style: italic;
font-display: swap;
}
@font-face {
    font-family:"Bodoni W01 LT";
    src:url("/fonts/8e76616d-ce24-4651-8581-84dcc7ae3751.eot?#iefix");
    src:url("/fonts/8e76616d-ce24-4651-8581-84dcc7ae3751.eot?#iefix") format("eot"),url("/fonts/08e83c1e-cf78-4018-9e48-bdb35c1a4967.woff2") format("woff2"),url("/fonts/6f551fc0-4c6f-4ef0-b026-f1e148977a7b.woff") format("woff"),url("/fonts/32eb1298-d5c4-4112-a2f5-ee0ac5051b72.ttf") format("truetype"),url("/fonts/79868027-7d4d-452f-a701-80ad10720730.svg#79868027-7d4d-452f-a701-80ad10720730") format("svg");
    font-weight: 700;
    font-style: italic;
    font-display: swap;
}
@font-face{
    font-family:"Proxima Nova W01";
    src:url("/fonts/c7dcb253-8dca-459b-aaa9-afef9131ef22.woff2") format("woff2"),url("/fonts/390be235-bf11-42ee-8ba1-2dd10ca6a4ac.woff") format("woff");
font-weight: 600;
font-style: normal;
font-display: swap;
}
@font-face {
    font-family:"Proxima Nova W01_n3";
    src:url("/fonts/7e90123f-e4a7-4689-b41f-6bcfe331c00a.eot?#iefix") format("eot");
    font-display: swap;
}
@font-face {
    font-family:"Proxima Nova W01";
    src:url("/fonts/7e90123f-e4a7-4689-b41f-6bcfe331c00a.eot?#iefix");
    src:url("/fonts/7e90123f-e4a7-4689-b41f-6bcfe331c00a.eot?#iefix") format("eot"),url("/fonts/64017d81-9430-4cba-8219-8f5cc28b923e.woff2") format("woff2"),url("/fonts/e56ecb6d-da41-4bd9-982d-2d295bec9ab0.woff") format("woff"),url("/fonts/2aff4f81-3e97-4a83-9e6c-45e33c024796.ttf") format("truetype"),url("/fonts/ab9cd062-380f-4b53-b1a7-c0bec7402235.svg#ab9cd062-380f-4b53-b1a7-c0bec7402235") format("svg");
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
@font-face{
    font-family:"Proxima Nova W01";
    src:url("/fonts/122f8a1b-fa6b-4246-8d37-911edb4ac556.woff2") format("woff2"),url("/fonts/0f51d8b8-bda2-4557-9b56-0a4e94ab63d9.woff") format("woff");
font-weight: 300;
font-style: normal;
font-display: swap;
}
@font-face {
    font-family:"Proxima Nova W01_n5";
    src:url("/fonts/fbc6b03a-b3a1-427c-a884-053deca3a53c.eot?#iefix") format("eot");
    font-display: swap;
}
@font-face {
    font-family:"Proxima Nova W01";
    src:url("/fonts/fbc6b03a-b3a1-427c-a884-053deca3a53c.eot?#iefix");
    src:url("/fonts/fbc6b03a-b3a1-427c-a884-053deca3a53c.eot?#iefix") format("eot"),url("/fonts/0d416408-95c6-4ad7-b08d-e60573d3b37d.woff2") format("woff2"),url("/fonts/d999c07b-a049-4eb5-b8a6-4f36ae25e67e.woff") format("woff"),url("/fonts/4d4a75f5-d32a-4a09-8665-133afd39cc37.ttf") format("truetype"),url("/fonts/8152bc4e-d700-4c78-b6be-326893e6f53f.svg#8152bc4e-d700-4c78-b6be-326893e6f53f") format("svg");
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
