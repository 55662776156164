.tabs {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.tabs--inactive {

    @include breakpoint(phablet) {
        display: none;
    }
}

    .tabs__list {
        display: flex;

        @include breakpoint(tablet) {
            width: 100%;
        }
    }

        .tabs__item {
            @include apply-map($f-primary);
            @include rem(12px);
            
            flex-grow: 1;
            width: 210px;
            font-weight: 600;
            line-height: 26px;
            letter-spacing: 2px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px 0;
            border: 1px solid $c-dark-grey;
            border-bottom: none;
            text-decoration: none;
            cursor: pointer;
            text-transform: uppercase;

            &:not(:last-child) {
                border-right: none;
            }

            @include breakpoint(tablet) {
                flex-grow: 1;
            }

            span {
                margin-left: 3px;
            }
        }

        .tabs__item--active {
            background: $c-dark-grey;
            color: $c-white;
        }

[data-tabs] {
    display: none;
}

[data-tabs-active="true"] {
    display: block;
}

[data-tabs-extra-active="true"] {
    display: block;
}